import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyRecord, preview as previewRecord} from "app/requests/admin/newsletters";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminNewslettersListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this newsletter?")) {
      destroyRecord(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(record.id); }
      });
    }
  };
  const onPreview = () => {
    if(window.confirm("Do you want to preview this newsletter?")) {
      previewRecord(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));
      });
    }
  };

  return (
    <tr>
      <td>{record.published_at ? displayDate(record.published_at) : "None"}</td>
      <td>{record.subject}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-success" href={`/newsletters/${record.uid}`}>View</a>
          <button type="button" className="btn btn-sm btn-warning" onClick={onPreview}>Preview</button>
          <a className="btn btn-sm btn-primary" href={`/admin/newsletters/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminNewslettersListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    published_at: PropTypes.string,
    subject: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default AdminNewslettersListRow;
