import {useMemo, useReducer} from "react";
import {Context, Reducer, createInitialState} from "app";
import Router from "components/router";

export default function Application(props) {
  const [reducer, dispatch] = useReducer(Reducer, props, createInitialState);
  const state = useMemo(() => ({...reducer, dispatch}), [reducer, dispatch]);

  return (
    <Context.Provider value={state}>
      <Router />
    </Context.Provider>
  );
}
