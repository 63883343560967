import {ActionText} from "components/helpers";
import {Sidebar} from "components/layout";
import {useContent, usePage} from "lib/hooks";

export default function Donate() {
  const content = useContent("Donate", "Content");
  const footer = useContent("Donate", "Footer");
  const link = useContent("Donate", "Link");

  usePage({heading: "Donate"});

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-lg-9">
          <div className="d-flex flex-column h-100">
            <div className="row">
              <div className="col-lg-8">
                <ActionText.Content html={content} />
              </div>
            </div>
            <p className="mt-5">
              <a className="btn btn-primary text-uppercase" href={link} rel="noopener noreferrer" target="_blank">
                Donate via PayPal
              </a>
            </p>
            <div className="mb-4 mt-auto text-dark">
              <ActionText.Content html={footer} />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
