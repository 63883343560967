import useAppContext from "lib/hooks/use-app-context";
import {usePrompt as useRouterPrompt} from "./react-router";

export default function usePrompt({message: _message, when}) {
  const {app: {environment}} = useAppContext();
  const development = environment !== "production";
  const message = _message || "Are you sure you want to leave without saving?";

  return useRouterPrompt(message, development ? false : when);
}
