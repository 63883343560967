import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {create as createContributer} from "app/requests/admin/contributers";
import {usePrompt} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import {valueFrom, valueFromTarget} from "lib/form";
import {withoutBlankValues} from "lib/object";
import Form from "./form";

export default function AdminContributersNew() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);
  const [contributer, setContributer] = useState({
    active: false,
    bio: "",
    name: "",
    rank: "",
    title: "",
    website: ""
  });

  usePrompt({when: block});

  const onChange = ({target}) => {
    setBlock(true);
    setContributer({...contributer, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (files) => {
    setBlock(false);
    createContributer({
      contributer: withoutBlankValues({...contributer, ...files})
    }).then((data) => {
      if(data.success) {
        navigate("/admin/contributers", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [contributer]});

  return (
    <div className="container-fluid">
      <h1 className="text-center">New Contributer</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
