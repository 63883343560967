import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getMessage} from "app/requests/admin/messages";
import {Loading} from "components/pages";

export default function AdminMessagesShow() {
  const {id} = useParams();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getMessage(id).then((data) => setMessage(data.record));
  }, []);

  if(!message) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 className="text-center">Message</h1>
          <div className="table-responsive rounded">
            <table className="table table-dark">
              <tbody>
                <tr className="bg-primary">
                  <th>From</th>
                  <td>{message.first_name} {message.last_name} ({message.email})</td>
                </tr>
                <tr className="bg-success">
                  <th>Subject</th>
                  <td>{message.subject}</td>
                </tr>
                <tr className="bg-secondary">
                  <th>Content</th>
                  <td className="preserve-whitespace">{message.content}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
