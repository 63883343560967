import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date-field";
import FileField from "components/helpers/form/file-field";
import TextListField from "components/helpers/form/text-list-field";
import FormWithFiles from "components/helpers/form/with-files";

function AdminChannelsForm({onChange, onSubmit, value}) {
  return (
    <FormWithFiles onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-author">Author</label>
            </div>
            <input
              className="form-control"
              id="channel-author"
              name="author"
              onChange={onChange}
              placeholder="Resa E Lewiss"
              required={true}
              type="text"
              value={value("author")}
            />
          </div>
          <div className="mb-3">
            <ContentEditor
              id="channel-description"
              label="Description"
              name="description"
              onChange={onChange}
              value={value("description")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-google-categories">Google Categories</label>
            </div>
            <TextListField
              className="form-control"
              id="channel-google-categories"
              name="google_categories"
              onChange={onChange}
              placeholder="Education, Health, Science & Medicine, Society & Culture"
              required={true}
              value={value("google_categories", [])}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-itunes-categories">iTunes Categories</label>
            </div>
            <TextListField
              className="form-control"
              id="channel-itunes-categories"
              name="itunes_categories"
              onChange={onChange}
              placeholder="Health & Fitness: Medicine"
              required={true}
              value={value("itunes_categories", [])}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-itunes-link">iTunes Link</label>
            </div>
            <input
              className="form-control"
              id="channel-itunes-link"
              name="itunes_link"
              onChange={onChange}
              placeholder="Link to Channel in iTunes"
              type="url"
              value={value("itunes_link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-keywords">Keywords</label>
            </div>
            <input
              className="form-control"
              id="channel-keywords"
              name="keywords"
              onChange={onChange}
              placeholder="Keywords"
              type="string"
              value={value("keywords")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-link">Link</label>
            </div>
            <input
              className="form-control"
              id="channel-link"
              name="link"
              onChange={onChange}
              placeholder={window.location.origin}
              required={true}
              type="url"
              value={value("link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-owner-email">Owner Email</label>
            </div>
            <input
              className="form-control"
              id="channel-owner-email"
              name="owner_email"
              onChange={onChange}
              placeholder="resaelewiss@gmail.com"
              required={true}
              type="email"
              value={value("owner_email")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-owner-name">Owner Name</label>
            </div>
            <input
              className="form-control"
              id="channel-owner-name"
              name="owner_name"
              onChange={onChange}
              placeholder="Resa E Lewiss"
              required={true}
              type="text"
              value={value("owner_name")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-published-at">Publish Date</label>
            </div>
            <DateField
              id="channel-published-at"
              name="published_at"
              onChange={onChange}
              value={value("published_at")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-spotify-link">Spotify Link</label>
            </div>
            <input
              className="form-control"
              id="channel-spotify-link"
              name="spotify_link"
              onChange={onChange}
              placeholder="Link to Channel in Spotify"
              type="url"
              value={value("spotify_link")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-subtitle">Subtitle</label>
            </div>
            <input
              className="form-control"
              id="channel-subtitle"
              name="subtitle"
              onChange={onChange}
              placeholder="Witty and Insightful"
              required={true}
              type="text"
              value={value("subtitle")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-title">Title</label>
            </div>
            <input
              className="form-control"
              id="channel-title"
              name="title"
              onChange={onChange}
              placeholder="The Visible Voices"
              required={true}
              type="text"
              value={value("title")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-image">Image</label>
            </div>
            <FileField id="channel-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Channel" className="img-fluid mt-2" src={value("image")} />}
          </div>
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("explicit", false)}
              className="custom-control-input"
              id="channel-explicit"
              name="explicit"
              onChange={onChange}
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="channel-explicit">Explicit</label>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="channel-redirect">Redirect</label>
            </div>
            <input
              className="form-control"
              id="channel-redirect"
              name="redirect"
              onChange={onChange}
              placeholder="https://podcast.com/migrate-your-podcast"
              type="url"
              value={value("redirect")}
            />
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </FormWithFiles>
  );
}

AdminChannelsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminChannelsForm;
