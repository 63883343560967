import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {get as getEpisode, update as updateEpisode} from "app/requests/admin/episodes";
import {Loading} from "components/pages";
import {valueFrom, valueFromTarget} from "lib/form";
import {usePrompt} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import Form from "./form";

export default function AdminEpisodesEdit() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const {id} = useParams();
  const [block, setBlock] = useState(false);
  const [changes, setChanges] = useState({});
  const [episode, setEpisode] = useState(null);

  usePrompt({when: block});
  useEffect(() => {
    getEpisode(id).then((data) => setEpisode(data.record));
  }, []);

  if(!episode) { return <Loading />; }

  const onChange = ({target}) => {
    setBlock(true);
    setChanges({...changes, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (files) => {
    const updates = {...changes, ...files};

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "danger"
      }));
    }

    setBlock(false);
    updateEpisode(episode.id, {episode: updates}).then((data) => {
      if(data.success) {
        navigate("/admin/episodes", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({
    defaultValue,
    name,
    objects: [changes, episode]
  });

  return (
    <div className="container-fluid">
      <h1 className="text-center">Edit Episode</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
