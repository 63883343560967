import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get} from "app/requests/episodes";
import {ActionText} from "components/helpers";
import {Sidebar} from "components/layout";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";

function EpisodesTranscript({getEpisode, prefix}) {
  const {uid} = useParams();
  const [episode, setEpisode] = useState(null);

  usePage({heading: "Transcript"});
  useEffect(() => {
    getEpisode(uid).then((data) => setEpisode(data.record));
  }, [uid]);

  if(!episode) { return <Loading />; }

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-lg-9">
          <div className="episode-content">
            <div className="row">
              <div className="col-md-8">
                <small className="d-flex text-muted text-uppercase">
                  <div>Episode {episode.kind === "Full" ? episode.number : episode.kind}</div>
                  <span className="mx-1"> | </span>
                  <a href={`${prefix}/episodes/${episode.uid}`}>Episode</a>
                </small>
                <h1 className="mb-4 text-uppercase">{episode.title}</h1>
                {episode.transcript ? (
                  <ActionText.Content html={episode.transcript} />
                ) : (
                  <div>Not yet available</div>
                )}
              </div>
              <div className="col-md-4">
                <small className="text-muted text-uppercase">&nbsp;</small>
                <img alt="Episode" className="img-fluid" src={episode.image} />
                {episode.player_url && <iframe className="mt-4" src={episode.player_url} title="Episode Audio" />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <small className="text-muted text-uppercase">&nbsp;</small>
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

EpisodesTranscript.defaultProps = {getEpisode: get, prefix: ""};
EpisodesTranscript.propTypes = {
  getEpisode: PropTypes.func,
  prefix: PropTypes.string
};

export default EpisodesTranscript;
