import {SET_USER} from "app/actions/user";

export default function user({action, state = null}) {
  switch(action.type) {
    case SET_USER: {
      const data = action.payload;

      return data ? {...data} : null;
    }
    default:
      return state;
  }
}
