import {useRef} from "react";
import {NavLink} from "react-router-dom";
import {useNavbar} from "lib/hooks";

export default function Navbar() {
  const ref = useRef(null);
  const {toggleMenu} = useNavbar({ref});

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark" ref={ref}>
      <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={toggleMenu}>
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mx-auto text-center">
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/dashboard">Dashboard</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/channels">Channels</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/sections">Content</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/contributers">Contributers</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/episodes">Episodes</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/messages">Messages</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/newsletters">Newsletters</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/posts">Posts</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/users">Users</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
