import {useMemo} from "react";
import useAppContext from "lib/hooks/use-app-context";

export default function useContent(sectionName, contentName) {
  const {sections} = useAppContext();

  const value = useMemo(() => {
    if(!sections) { return; }

    const section = sections.find(({name}) => name === sectionName);
    if(!section) { return; }

    const content = section.contents.find(({name}) => name === contentName);
    if(!content) { return; }

    return content.value;
  }, [sections]);

  return value;
}
