import {useEffect, useState} from "react";
import {getAll as getContributers} from "app/requests/admin/contributers";
import Row from "./row";

export default function AdminContributersList() {
  const [contributers, setContributers] = useState([]);
  const onDestroy = (id) => {
    setContributers(contributers.filter((contributer) => contributer.id !== id));
  };

  useEffect(() => {
    getContributers().then((data) => setContributers(data.contributers));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center">Contributers</h1>
          <div className="text-right mb-1">
            <a className="btn btn-outline-secondary btn-sm" href="/admin/contributers/new">New Contributer</a>
          </div>
          <div className="table-responsive rounded">
            <table className="table table-hover text-center">
              <thead className="thead-dark">
                <tr>
                  <th>Active</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Rank</th>
                  <th>Title</th>
                  <th>Website</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {contributers.map((contributer) => (
                  <Row key={contributer.id} onDestroy={onDestroy} contributer={contributer} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
