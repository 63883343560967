import {useEffect, useState} from "react";
import {getAll as getPosts} from "app/requests/posts";
import Pagination from "components/helpers/pagination";
import {Loading} from "components/pages";
import {useQueryParams} from "lib/hooks";
import {withoutBlankValues} from "lib/object";
import {displayDate} from "lib/string";

export default function Posts() {
  const params = useQueryParams();
  const initialPage = params.get("page");
  const initialSearch = params.get("value");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(initialPage ? +initialPage : 1);

  useEffect(() => {
    setLoading(true);
    getPosts(withoutBlankValues({page: pageNumber, search: initialSearch})).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber]);

  if(loading) { return <Loading />; }

  return (
    <>
      {page.records.length === 0 && (
        <div>
          <span>There are no posts for </span>
          <span className="m-1">{initialSearch || "All"}</span>
          <a href="/search">
            <i className="fas fa-times-circle" />
          </a>
        </div>
      )}
      <div className="row">
        {page.records.map((record) => (
          <div key={record.uid} className="col-lg-6 mb-4 mt-1">
            <div className="font-weight-bold extra-small text-muted text-uppercase">
              Published on {displayDate(record.published_at)}
            </div>
            <h3 className="mt-0">
              <a className="text-black" href={`/blog/posts/${record.uid}`}>{record.title}</a>
            </h3>
            <div className="font-weight-light letter-spacing-wide text-muted text-uppercase">{record.author}</div>
            {(record.tags.length > 0) && (
              <div className="my-2">
                {record.tags.map((tag) => (
                  <a key={tag} className="extra-small d-inline-block mr-2 text-uppercase" href={`/blog/tags/${tag}`}>{tag}</a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="text-center">
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
    </>
  );
}
