import {getAll as getRecords} from "app/requests/admin/users";
import PaginateTable from "components/helpers/paginate-table";
import Row from "./row";

const Heading = () => (
  <tr>
    <th>Admin</th>
    <th>Invited</th>
    <th>Subscribed</th>
    <th>Name</th>
    <th>Email</th>
    <th>Date Created</th>
    <th>Actions</th>
  </tr>
);

export default function AdminUsersList() {
  const options = {Heading, Row, getRecords};

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="text-right mb-1">
            <a className="btn btn-outline-secondary btn-sm" href="/admin/users/new">Invite</a>
          </div>
          <h1 className="text-center">Users</h1>
          <PaginateTable {...options} />
        </div>
      </div>
    </div>
  );
}
