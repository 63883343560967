import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {create as createSession} from "app/requests/session";
import {Sidebar} from "components/layout";
import {usePage} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";

export default function Login() {
  const {dispatch, user} = useAppContext();
  const [record, setChanges] = useState({email: "", password: ""});
  const navigate = useNavigate();

  usePage({heading: "Login"});
  useEffect(() => {
    if(user) { navigate("/profile"); }
  }, [user]);

  const onChange = ({target: {name, value}}) => (
    setChanges({...record, [name]: value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    createSession({user: record}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "primary" : "danger"
      }));

      if(data.success) { dispatch(setUser(data.user)); }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="row mt-3">
            <div className="col-lg-6">
              <form className="borderless-form" onSubmit={onSubmit}>
                <div className="input-group mb-4">
                  <input
                    className="form-control py-4"
                    id="session-email"
                    name="email"
                    onChange={onChange}
                    placeholder="Email"
                    required={true}
                    type="email"
                    value={record.email}
                  />
                </div>
                <div className="input-group mb-4">
                  <input
                    className="form-control py-4"
                    id="session-password"
                    name="password"
                    onChange={onChange}
                    placeholder="Password"
                    required={true}
                    type="password"
                    value={record.password}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary px-5">Sign In</button>
                  <a className="btn btn-link btn-sm" href="/forgot-password">Forgot Password?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
