export default function Credits() {
  return (
    <div className="extra-small">
      <p className="mb-1">
        This work is licensed under CC BY-NC-SA 4.0
        <a href="https://creativecommons.org/licenses/by-nc-sa/4.0" rel="noopener noreferrer" target="_blank">
          <i className="fab fa-creative-commons fa-lg ml-1" />
          <i className="fab fa-creative-commons-by fa-lg ml-1" />
          <i className="fab fa-creative-commons-nc fa-lg ml-1" />
          <i className="fab fa-creative-commons-sa fa-lg ml-1" />
        </a>
        <span className="mx-1"> | </span>
        <a href="/disclaimer">Disclaimer</a>
      </p>
      <p>
        Design by <a href="https://aasawarikulkarni.com" rel="noopener noreferrer" target="_blank">Aasawari Kulkarni</a>
        <span className="mx-1"> | </span>
        Website by <a href="https://www.tomify.me" rel="noopener noreferrer" target="_blank">Tomify</a>
      </p>
    </div>
  );
}
