import {useEffect} from "react";
import {setPage} from "app/actions/page";
import useAppContext from "lib/hooks/use-app-context";

export default function usePage(page) {
  const {dispatch} = useAppContext();

  useEffect(() => {
    dispatch(setPage({...page}));

    return () => dispatch(setPage({}));
  }, []);
}
