import {useEffect, useState} from "react";
import {getAll as getEpisodes} from "app/requests/episodes";
import {ActionText} from "components/helpers";
import Pagination from "components/helpers/pagination";
import {Loading} from "components/pages";
import {useQueryParams} from "lib/hooks";
import {withoutBlankValues} from "lib/object";
import {displayDate} from "lib/string";

export default function Episodes() {
  const params = useQueryParams();
  const initialPage = params.get("page");
  const initialSearch = params.get("value");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(initialPage ? +initialPage : 1);

  useEffect(() => {
    setLoading(true);
    getEpisodes(withoutBlankValues({page: pageNumber, search: initialSearch})).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber]);

  if(loading) { return <Loading />; }

  return (
    <>
      {page.records.length === 0 && (
        <div>
          <span>There are no episodes for </span>
          <span className="m-1">{initialSearch || "All"}</span>
          <a href="/search">
            <i className="fas fa-times-circle" />
          </a>
        </div>
      )}
      <div className="row">
        {page.records.map((record) => (
          <div key={record.uid} className="col-lg-6 mb-4 mt-1">
            <a className="d-block mb-4" href={`/episodes/${record.uid}`}>
              <img alt="Episode" className="img-fluid" src={record.image} />
            </a>
            <ActionText.Content html={record.description} truncate={{url: `/episodes/${record.uid}`}} />
            <p className="font-weight-bold mt-1 extra-small text-muted text-uppercase">Published on {displayDate(record.published_at)}</p>
          </div>
        ))}
      </div>
      <div className="text-center">
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
    </>
  );
}
