import {start as ActiveStorageStart} from "@rails/activestorage";
import "lib/action-text"; // Rails broke it
import "@tomprats/font-awesome-pro/js/all";
import Rails from "@rails/ujs";
import "core-js/stable";
import {createRoot} from "react-dom/client";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import Application from "components/application";
import "lib/trix";
import "app/assets/style.external.scss";
import "app/assets/style.scss";

ActiveStorageStart();
Rails.start();
window.FontAwesome.config.autoReplaceSvg = "nest";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".react-component").forEach((node) => {
    const props = JSON.parse(node.getAttribute("data"));
    const root = createRoot(node);

    root.render(<Application {...props} />);
  });
});
