import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {copy as copyEpisode, destroy as destroyEpisode} from "app/requests/admin/episodes";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminEpisodesListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const onCopy = () => {
    if(window.confirm("Are you sure?")) {
      copyEpisode(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { navigate(`/admin/episodes/${data.record.id}`, {message: data.message, type: "success"}); }
      });
    }
  };
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this episode?")) {
      destroyEpisode(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(record.id); }
      });
    }
  };

  return (
    <tr>
      <td>{record.published_at ? displayDate(record.published_at) : "None"}</td>
      <td><a href={record.audio} rel="noopener noreferrer" target="_blank">View</a></td>
      <td>{record.author}</td>
      <td>{record.channel.title}</td>
      <td><a href={record.image} rel="noopener noreferrer" target="_blank">View</a></td>
      <td>{record.kind}</td>
      <td>{record.title}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <button className="btn btn-sm btn-warning" onClick={onCopy} type="button">Copy</button>
          <a className="btn btn-sm btn-primary" href={`/admin/episodes/${record.id}`}>Edit</a>
          <button className="btn btn-sm btn-danger" onClick={onDelete} type="button">Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminEpisodesListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    audio: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    channel: PropTypes.shape({title: PropTypes.string.isRequired}).isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default AdminEpisodesListRow;
