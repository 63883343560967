import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {create as createPost} from "app/requests/admin/posts";
import {usePrompt} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import {valueFrom, valueFromTarget} from "lib/form";
import {withoutBlankValues} from "lib/object";
import Form from "./form";

export default function AdminPostsNew() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);
  const [post, setPost] = useState({
    author: "",
    content: "",
    email_content: "",
    email_preview_text: "",
    email_subject: "",
    tags: [],
    title: ""
  });

  usePrompt({when: block});

  const onChange = ({target}) => {
    setBlock(true);
    setPost({...post, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    setBlock(false);
    createPost({post: withoutBlankValues({...post})}).then((data) => {
      if(data.success) {
        navigate("/admin/posts", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [post]});

  return (
    <div className="container-fluid">
      <h1 className="text-center">New Post</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
