import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {ActionText} from "components/helpers";
import User from "components/pages/subscribe/user";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

const storageKey = "hide-subscribe-modal";
const validLocation = (location) => {
  if(location.pathname === "/") { return true; }
  if(location.pathname.startsWith("/episodes")) { return true; }

  return false;
};

export default function SubscribeModal() {
  const {app, user} = useAppContext();
  const content = useContent("Subscribe", "Content");
  const disable = useContent("Subscribe", "Disable");
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showing, setShowing] = useState(false);

  const hideModal = () => {
    localStorage.setItem(storageKey, true);
    setShow(false);
  };

  useEffect(() => {
    if(app.loading) { return; }
    if(disable) { return; }
    if(!validLocation(location)) { return; }
    if(localStorage.getItem(storageKey)) { return; }
    if(user) {
      hideModal();
      return;
    }

    setShowing(true);

    const timeout = setTimeout(() => setShow(true), 3000);

    return () => {
      clearTimeout(timeout);
      setShowing(false);
    };
  }, [app.loading, location.pathname, user]);

  if(!showing) { return null; }

  return (
    <div className={`modal fade ${show ? "d-block show" : ""}`} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Subscribe</h4>
            <button className="close" onClick={hideModal} type="button">
              <i className="fas fa-times" />
            </button>
          </div>
          <div className="modal-body">
            {content && <ActionText.Content className="mb-3 text-dark" html={content} />}
            <User />
          </div>
        </div>
      </div>
    </div>
  );
}
