import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// Similar to https://react-bootstrap.netlify.com/components/navbar/
export default function useNavbar({ref}) {
  const location = useLocation();
  const closeMenu = () => {
    const collapse = ref.current.querySelector(".navbar-collapse");

    collapse.classList.add("collapse");
    collapse.classList.remove("in");
    ref.current.classList.remove("navbar-open");
  };

  const toggleMenu = () => {
    const collapse = ref.current.querySelector(".navbar-collapse");

    collapse.classList.toggle("collapse");
    collapse.classList.toggle("in");
    ref.current.classList.toggle("navbar-open");
  };

  useEffect(() => {
    const closeMenuOnResize = () => {
      if(document.body.clientWidth < 768) { return; }

      closeMenu();
    };

    window.addEventListener("resize", closeMenuOnResize, false);

    return () => {
      window.removeEventListener("resize", closeMenuOnResize, false);
    };
  }, []);

  useEffect(() => { closeMenu(); }, [location]);

  return {toggleMenu};
}
