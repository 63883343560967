import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyPost} from "app/requests/admin/posts";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminPostsListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this post?")) {
      destroyPost(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(record.id); }
      });
    }
  };

  return (
    <tr>
      <td>{record.published_at ? displayDate(record.published_at) : "None"}</td>
      <td>{record.author}</td>
      <td>
        {record.episode && <a href={`/admin/episodes/${record.episode.id}`}>{record.episode.title}</a>}
      </td>
      <td>
        {record.tags.map((tag) => (
          <span key={tag} className="badge badge-success mx-1">{tag}</span>
        ))}
      </td>
      <td>{record.title}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/posts/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminPostsListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    author: PropTypes.string.isRequired,
    episode: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    published_at: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default AdminPostsListRow;
