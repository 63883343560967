import {getAll as getRecords} from "app/requests/admin/newsletters";
import PaginateTable from "components/helpers/paginate-table";
import Row from "./row";

const Heading = () => (
  <tr>
    <th>Published</th>
    <th>Subject</th>
    <th>Actions</th>
  </tr>
);

export default function AdminNewslettersList() {
  const options = {Heading, Row, getRecords};

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-12 text-right mb-1">
              <a className="btn btn-outline-secondary btn-sm" href="/admin/newsletters/new">New Newsletter</a>
            </div>
          </div>
          <h1 className="text-center">Newsletters</h1>
          <PaginateTable {...options} />
        </div>
      </div>
    </div>
  );
}
