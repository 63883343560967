import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {getAll as getChannels} from "app/requests/admin/channels";
import {create as createEpisode} from "app/requests/admin/episodes";
import {usePrompt} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import {valueFrom, valueFromTarget} from "lib/form";
import {withoutBlankValues} from "lib/object";
import Form from "./form";

export default function AdminEpisodesNew() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);
  const [channels, setChannels] = useState([]);
  const [episode, setEpisode] = useState({
    author: "",
    description: "",
    email_content: "",
    email_preview_text: "",
    email_subject: "",
    explicit: false,
    kind: "Full",
    subtitle: "",
    title: ""
  });

  usePrompt({when: block});
  useEffect(() => {
    getChannels().then((data) => setChannels(data.channels));
  }, []);

  useEffect(() => {
    if(channels.length > 0) { setEpisode({...episode, channel_id: channels[0].id}); }
  }, [channels]);

  const onChange = ({target}) => {
    setBlock(true);
    setEpisode({...episode, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (files) => {
    setBlock(false);
    createEpisode({episode: withoutBlankValues({...episode, ...files})}).then((data) => {
      if(data.success) {
        navigate("/admin/episodes", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [episode]});

  return (
    <div className="container-fluid">
      <h1 className="text-center">New Episode</h1>
      <Form {...{channels, onChange, onSubmit, value}} />
    </div>
  );
}
