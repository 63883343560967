import PropTypes from "prop-types";
import {useEffect, useState} from "react";

function TextListField({onChange, value, ...props}) {
  const [text, setText] = useState("");
  const onTextChange = ({target: {name, type, value: newText}}) => {
    const list = newText.split(",").map((item) => item.trim()).filter((item) => item);

    setText(newText);
    onChange({target: {name, type, value: list}});
  };

  useEffect(() => {
    const newText = value.join(", ");

    if(text) { return; }
    if(!newText) { return; }

    setText(newText);
  }, [value]);

  return <input type="text" value={text} onChange={onTextChange} {...props} />;
}

TextListField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TextListField;
