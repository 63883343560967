import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import Pagination from "components/helpers/pagination";
import Loading from "components/pages/loading";
import useQueryParams from "lib/hooks/use-query-params";
import {withoutBlankValues} from "lib/object";

function PaginateTable({Heading, Row, getRecords}) {
  const params = useQueryParams();
  const initialPage = params.get("page");
  const initialSearch = params.get("search");
  const [currentSearch, setCurrentSearch] = useState(initialSearch || "");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(initialPage ? +initialPage : 1);
  const [search, setSearch] = useState(initialSearch);
  const onDestroy = (id) => {
    setPage({...page, records: page.records.filter((record) => record.id !== id)});
  };

  useEffect(() => {
    getRecords(withoutBlankValues({page: pageNumber, search})).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber, search]);

  if(loading) { return <Loading />; }

  const onSearch = (e) => {
    e.preventDefault();

    setSearch(currentSearch);
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <form className="form-inline" onSubmit={onSearch}>
          <input
            className="form-control-sm mr-sm-2"
            name="value"
            onChange={({target: {value}}) => setCurrentSearch(value)}
            placeholder="Search"
            type="search"
            value={currentSearch || ""}
          />
          <button className="btn btn-primary btn-sm" type="submit">Search</button>
        </form>
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
      <div className="table-responsive rounded">
        <table className="table table-hover text-center">
          <thead className="thead-dark">
            <Heading />
          </thead>
          <tbody>
            {page.records.map((record) => (
              <Row key={record.id} onDestroy={onDestroy} record={record} />
            ))}
          </tbody>
        </table>
      </div>
      {page.records.length === 0 && <div className="text-center">Nothing found</div>}
      <div className="text-right">
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
    </div>
  );
}

PaginateTable.propTypes = {
  getRecords: PropTypes.func.isRequired,
  Heading: PropTypes.elementType.isRequired,
  Row: PropTypes.elementType.isRequired
};

export default PaginateTable;
