import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {Check} from "components/helpers";
import {destroy as destroyUser} from "app/requests/admin/users";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminUsersListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this user?")) {
      destroyUser(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(record.id); }
      });
    }
  };

  return (
    <tr>
      <td><Check checked={record.admin} /></td>
      <td><Check checked={record.invited} /></td>
      <td className="text-left">
        <Check checked={record.post_notifications} /> Episodes
        <br />
        <Check checked={record.newsletter_notifications} /> Newsletter
      </td>
      <td>{[record.first_name, record.last_name].join(" ") || "Unknown"}</td>
      <td>{record.email}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/users/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminUsersListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    admin: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    invited: PropTypes.bool.isRequired,
    last_name: PropTypes.string,
    newsletter_notifications: PropTypes.bool.isRequired,
    post_notifications: PropTypes.bool.isRequired
  }).isRequired
};

export default AdminUsersListRow;
