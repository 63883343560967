import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll} from "app/requests/episodes";
import {EpisodeTile} from "components/helpers";
import Pagination from "components/helpers/pagination";
import {Loading} from "components/pages";
import usePage from "lib/hooks/use-page";
import useQueryParams from "lib/hooks/use-query-params";
import {withoutBlankValues} from "lib/object";

function EpisodesList({getEpisodes, prefix}) {
  const params = useQueryParams();
  const initialPage = params.get("page");
  const initialSearch = params.get("search");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(initialPage ? +initialPage : 1);

  usePage({heading: "Podcast"});
  useEffect(() => {
    setLoading(true);
    getEpisodes(withoutBlankValues({page: pageNumber, search: initialSearch})).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber]);

  if(loading) { return <Loading />; }

  return (
    <div className="container-fluid">
      {page.search && (
        <>
          <h3>Showing results for "{page.search}"</h3>
          <hr />
        </>
      )}
      {page.records.length === 0 && <div className="text-center">Nothing found</div>}
      <div className="row">
        {page.records.map((record) => (
          <div key={record.uid} className="col-md-3 mb-4 mt-1">
            <EpisodeTile episode={record} prefix={prefix} />
          </div>
        ))}
      </div>
      <div className="text-center">
        <Pagination page={page} updatePage={setPageNumber} />
      </div>
    </div>
  );
}

EpisodesList.defaultProps = {getEpisodes: getAll, prefix: ""};
EpisodesList.propTypes = {
  getEpisodes: PropTypes.func,
  prefix: PropTypes.string
};

export default EpisodesList;
