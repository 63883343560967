import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getRecord} from "app/requests/newsletters";
import {ActionText} from "components/helpers";
import {Sidebar} from "components/layout";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";
import {displayDate} from "lib/string";

export default function NewslettersShow() {
  const {uid} = useParams();
  const [record, setRecord] = useState(null);

  usePage({heading: "Newsletter"});
  useEffect(() => {
    getRecord(uid).then((data) => setRecord(data.record));
  }, [uid]);

  if(!record) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="font-weight-bold extra-small text-muted text-uppercase">
            Published on {displayDate(record.published_at)}
          </div>
          <h1 className="mb-4">{record.subject}</h1>
          <ActionText.Content html={record.content} />
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
