import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import * as Layout from "components/layout";
import * as Pages from "components/pages";
import SubscribeModal from "components/helpers/subscribe-modal";
import useAppContext from "lib/hooks/use-app-context";

export default function Router() {
  const {user} = useAppContext();
  const admin = user && user.admin;

  return (
    <BrowserRouter>
      <div className="app">
        {admin && <Layout.Admin.Navbar />}
        <Layout.Analytics />
        <Layout.Navbar />
        <Layout.Notifications />
        <SubscribeModal />
        <div className="page d-flex flex-column">
          <Layout.ErrorBoundary>
            <Routes>
              <Route path="/" element={<Pages.Episodes.List />} />
              <Route path="/about" element={<Pages.About />} />
              <Route path="/contact" element={<Pages.Contact />} />
              <Route path="/disclaimer" element={<Pages.Disclaimer />} />
              <Route path="/donate" element={<Pages.Donate />} />
              <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
              <Route path="/blog" element={<Navigate to="/blog/posts" />} />
              <Route path="/blog/posts/:uid" element={<Pages.Blog.Posts.Show />} />
              <Route path="/blog/posts" element={<Pages.Blog.Posts.List />} />
              <Route path="/blog/tags/:tag" element={<Pages.Blog.Posts.List />} />
              <Route path="/episodes" element={<Navigate to="/" />} />
              <Route path="/episodes/:uid/transcript" element={<Pages.Episodes.Transcript />} />
              <Route path="/episodes/:uid" element={<Pages.Episodes.Show />} />
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/newsletters/:uid" element={<Pages.Newsletters.Show />} />
              <Route path="/podcast" element={<Navigate to="/episodes" />} />
              <Route path="/profile" element={<Pages.Profile />} />
              <Route path="/search" element={<Pages.Search />} />
              <Route path="/subscribe" element={<Pages.Subscribe />} />
              <Route path="/transcripts" element={<Pages.Transcripts />} />
              <Route path="/unsubscribe" element={<Pages.Unsubscribe />} />
              <Route path="/videos" element={<Pages.Videos />} />
              {!admin && <Route path="/admin/*" element={<Navigate to="/subscribe" />} />}
              <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
              <Route path="/admin/dashboard" element={<Pages.Admin.Dashboard />} />
              <Route path="/admin/channels/new" element={<Pages.Admin.Channels.New />} />
              <Route path="/admin/channels/:id" element={<Pages.Admin.Channels.Edit />} />
              <Route path="/admin/channels" element={<Pages.Admin.Channels.List />} />
              <Route path="/admin/contributers/new" element={<Pages.Admin.Contributers.New />} />
              <Route path="/admin/contributers/:id" element={<Pages.Admin.Contributers.Edit />} />
              <Route path="/admin/contributers" element={<Pages.Admin.Contributers.List />} />
              <Route path="/admin/episodes/new" element={<Pages.Admin.Episodes.New />} />
              <Route path="/admin/episodes/:id" element={<Pages.Admin.Episodes.Edit />} />
              <Route path="/admin/episodes" element={<Pages.Admin.Episodes.List />} />
              <Route path="/admin/messages/:id" element={<Pages.Admin.Messages.Show />} />
              <Route path="/admin/messages" element={<Pages.Admin.Messages.List />} />
              <Route path="/admin/newsletters/new" element={<Pages.Admin.Newsletters.New />} />
              <Route path="/admin/newsletters/:id" element={<Pages.Admin.Newsletters.Edit />} />
              <Route path="/admin/newsletters" element={<Pages.Admin.Newsletters.List />} />
              <Route path="/admin/posts/new" element={<Pages.Admin.Posts.New />} />
              <Route path="/admin/posts/:id" element={<Pages.Admin.Posts.Edit />} />
              <Route path="/admin/posts" element={<Pages.Admin.Posts.List />} />
              <Route path="/admin/sections/:id" element={<Pages.Admin.Sections.Edit />} />
              <Route path="/admin/sections" element={<Pages.Admin.Sections.List />} />
              <Route path="/admin/users/new" element={<Pages.Admin.Users.New />} />
              <Route path="/admin/users/:id" element={<Pages.Admin.Users.Edit />} />
              <Route path="/admin/users" element={<Pages.Admin.Users.List />} />
              {!admin && <Route path="/preview/*" element={<Navigate to="/subscribe" />} />}
              <Route path="/preview" element={<Navigate to="/preview/episodes" />} />
              <Route path="/preview/blog" element={<Navigate to="/preview/blog/posts" />} />
              <Route path="/preview/blog/posts/:uid" element={<Pages.Preview.Blog.Posts.Show />} />
              <Route path="/preview/blog/posts" element={<Pages.Preview.Blog.Posts.List />} />
              <Route path="/preview/blog/tags/:tag" element={<Pages.Preview.Blog.Posts.List />} />
              <Route path="/preview/episodes/:uid/transcript" element={<Pages.Preview.Episodes.Transcript />} />
              <Route path="/preview/episodes/:uid" element={<Pages.Preview.Episodes.Show />} />
              <Route path="/preview/episodes" element={<Pages.Preview.Episodes.List />} />
              <Route path="/preview/podcast" element={<Navigate to="/preview/episodes" />} />
              <Route path="/preview/transcripts" element={<Pages.Preview.Transcripts />} />
              <Route element={<Pages.NotFound />} />
            </Routes>
          </Layout.ErrorBoundary>
          <Layout.Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}
