import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get} from "app/requests/posts";
import {ActionText, EpisodeTile} from "components/helpers";
import {Sidebar} from "components/layout";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";
import {displayDate} from "lib/string";

function PostsShow({getPost, prefix}) {
  const {uid} = useParams();
  const [post, setPost] = useState(null);

  usePage({heading: "Blog"});
  useEffect(() => {
    getPost(uid).then((data) => setPost(data.record));
  }, [uid]);

  if(!post) { return <Loading />; }

  const updatedOn = Date.parse(post.updated_at) > Date.parse(post.published_at)
    ? post.updated_at
    : post.published_at;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="row">
            <div className="col-md-8">
              <div className="font-weight-bold extra-small text-muted text-uppercase">
                Published on {displayDate(updatedOn)}
              </div>
              <h1 className="mb-4">{post.title}</h1>
              <ActionText.Content html={post.content} />
              <div className="mt-3 text-dark text-uppercase">{post.author}</div>
              {(post.tags.length > 0) && (
                <div className="mb-2">
                  {post.tags.map((tag) => (
                    <a key={tag} className="extra-small mr-2 text-uppercase" href={`${prefix}/blog/tags/${tag}`}>{tag}</a>
                  ))}
                </div>
              )}
            </div>
            {post.episode && (
              <div className="col-md-4">
                <EpisodeTile episode={post.episode} prefix={prefix} />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

PostsShow.defaultProps = {getPost: get, prefix: ""};
PostsShow.propTypes = {
  getPost: PropTypes.func,
  prefix: PropTypes.string
};

export default PostsShow;
