import {usePage, useQueryParams} from "lib/hooks";
import Episodes from "./episodes";
import Posts from "./posts";

export default function Search() {
  const params = useQueryParams();
  const initialSearch = params.get("value");

  usePage({heading: "Search"});

  return (
    <div className="container-fluid">
      <div className="mb-4">
        <span>Showing results for </span>
        <span className="m-1">{initialSearch || "All"}</span>
        <a href="/search">
          <i className="fas fa-times-circle" />
        </a>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h1 className="mb-4">Podcast</h1>
          <Episodes />
        </div>
        <div className="col-md-6">
          <h1 className="mb-4">Blog</h1>
          <Posts />
        </div>
      </div>
    </div>
  );
}
