import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getEpisodes} from "app/requests/admin/episodes";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date-field";
import TextListField from "components/helpers/form/text-list-field";

function AdminPostsForm({onChange, onSubmit, value}) {
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    getEpisodes().then((data) => setEpisodes(data.records));
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-author">Author</label>
            </div>
            <input
              className="form-control"
              id="post-author"
              name="author"
              onChange={onChange}
              placeholder="Resa E Lewiss"
              required={true}
              type="text"
              value={value("author")}
            />
          </div>
          <div className="mb-3">
            <ContentEditor
              id="post-content"
              label="Content"
              name="content"
              onChange={onChange}
              value={value("content")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-episode-id">Episode</label>
            </div>
            <select
              className="custom-select"
              id="post-episode-id"
              name="episode_id"
              onChange={onChange}
              required={true}
              value={value("episode_id")}
            >
              <option key="none" value="">None</option>
              {episodes.map((episode) => (
                <option key={episode.id} value={episode.id}>{episode.title}</option>
              ))}
            </select>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-published-at">Publish Date</label>
            </div>
            <DateField
              id="post-published-at"
              name="published_at"
              onChange={onChange}
              value={value("published_at")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-tags">Tags</label>
            </div>
            <TextListField
              className="form-control"
              id="post-tags"
              name="tags"
              onChange={onChange}
              placeholder="covid-19, puns, star wars"
              value={value("tags", [])}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-title">Title</label>
            </div>
            <input
              className="form-control"
              id="post-title"
              name="title"
              onChange={onChange}
              placeholder="The Visible Voices"
              required={true}
              type="text"
              value={value("title")}
            />
          </div>
          <div className="mb-3">
            <ContentEditor
              id="post-email-content"
              label="Email Content"
              name="email_content"
              onChange={onChange}
              value={value("email_content")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-email-preview-text">Email Preview Text</label>
            </div>
            <input
              className="form-control"
              id="post-email-preview-text"
              name="email_preview_text"
              onChange={onChange}
              placeholder="Check this out!"
              type="text"
              value={value("email_preview_text")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="post-email-subject">Email Subject</label>
            </div>
            <input
              className="form-control"
              id="post-email-subject"
              name="email_subject"
              onChange={onChange}
              placeholder="Visible Voices - Post 100"
              type="text"
              value={value("email_subject")}
            />
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}

AdminPostsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminPostsForm;
