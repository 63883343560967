import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {update as updateUser} from "app/requests/user";
import {usePage} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";

export default function Unsubscribe() {
  const {dispatch, user} = useAppContext();
  const navigate = useNavigate();

  usePage({heading: "Unsubscribe"});
  useEffect(() => {
    if(!user) { navigate("/subscribe"); }
  }, [user]);

  useEffect(() => {
    const changes = {
      newsletter_notifications: false,
      post_notifications: false
    };

    updateUser({user: changes}).then((data) => {
      if(!data.success) {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }

      if(data.success) {
        dispatch(createNotification({content: "Unsubscribed from all notifications", type: "success"}));
        dispatch(setUser(data.user));
      }
    });
  }, []);

  if(!user) { return null; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="mb-5">The Visible Voices</h2>
          <p>You are automatically being unsubscribed.</p>
        </div>
      </div>
    </div>
  );
}
