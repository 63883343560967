import {ActionText} from "components/helpers";
import {useContent, usePage} from "lib/hooks";

export default function Contact() {
  const content = useContent("Contact", "Content");

  usePage({heading: "Contact"});

  return (
    <div className="container-fluid">
      <ActionText.Content className="mb-5 text-dark" html={content} />
    </div>
  );
}
