import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date-field";

function AdminNewslettersForm({onChange, onSubmit, value}) {
  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="mb-3">
            <ContentEditor
              id="record-content"
              label="Content"
              name="content"
              onChange={onChange}
              value={value("content")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-preview-text">Preview Text</label>
            </div>
            <input
              className="form-control"
              id="record-preview-text"
              name="preview_text"
              onChange={onChange}
              placeholder="Check this out!"
              type="text"
              value={value("preview_text")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-published-at">Publish Date</label>
            </div>
            <DateField
              id="record-published-at"
              name="published_at"
              onChange={onChange}
              value={value("published_at")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-subject">Subject</label>
            </div>
            <input
              className="form-control"
              id="record-subject"
              name="subject"
              onChange={onChange}
              placeholder="The Visible Voices Newsletter"
              required={true}
              type="text"
              value={value("subject")}
            />
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}

AdminNewslettersForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminNewslettersForm;
