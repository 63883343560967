import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {create as createUser} from "app/requests/admin/users";
import {usePrompt} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import {valueFrom, valueFromTarget} from "lib/form";
import {withoutBlankValues} from "lib/object";
import Form from "./form";

export default function AdminUsersNew() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);
  const [user, setUser] = useState({
    admin: false,
    email: "",
    first_name: "",
    invited: true,
    last_name: "",
    newsletter_notifications: false,
    post_notifications: false
  });

  usePrompt({when: block});

  const onChange = ({target}) => {
    setBlock(true);
    setUser({...user, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (files) => {
    setBlock(false);
    createUser({user: withoutBlankValues({...user, ...files})}).then((data) => {
      if(data.success) {
        navigate("/admin/users", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [user]});

  return (
    <div className="container-fluid">
      <h1 className="text-center">Invite User</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
