import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyContributer} from "app/requests/admin/contributers";
import useAppContext from "lib/hooks/use-app-context";

function AdminContributersListRow({onDestroy, contributer}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this contributer?")) {
      destroyContributer(contributer.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(contributer.id); }
      });
    }
  };

  return (
    <tr>
      <td>{contributer.active ? "Yes" : "No"}</td>
      <td>{contributer.image ? <a href={contributer.image} rel="noopener noreferrer" target="_blank">View</a> : "None"}</td>
      <td>{contributer.name}</td>
      <td>{contributer.rank}</td>
      <td>{contributer.title}</td>
      <td>{contributer.website || "None"}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/contributers/${contributer.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminContributersListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  contributer: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    website: PropTypes.string
  }).isRequired
};

export default AdminContributersListRow;
