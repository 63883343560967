import {getAll as getRecords} from "app/requests/admin/messages";
import PaginateTable from "components/helpers/paginate-table";
import Row from "./row";

const Heading = () => (
  <tr>
    <th>Name</th>
    <th>Email</th>
    <th>Subject</th>
    <th>Date Created</th>
    <th>Actions</th>
  </tr>
);

export default function AdminMessagesList() {
  const options = {Heading, Row, getRecords};

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center">Messages</h1>
          <PaginateTable {...options} />
        </div>
      </div>
    </div>
  );
}
