import notifications from "./notifications";
import page from "./page";
import user from "./user";

const defaultReducer = ({state}) => state;

function combineReducers(reducers) {
  return (state, action) => {
    const newState = {};

    Object.keys(reducers).forEach((key) => {
      newState[key] = reducers[key]({action, state: state[key]});
    });

    return newState;
  };
}

export const createInitialState = (props) => ({
  app: props.app,
  channel: props.channel,
  notifications: props.notifications || [],
  page: {},
  sections: props.sections,
  user: props.user
});

export default combineReducers({
  app: defaultReducer,
  channel: defaultReducer,
  notifications,
  page,
  sections: defaultReducer,
  user
});
