import PropTypes from "prop-types";
import FileField from "components/helpers/form/file-field";
import FormWithFiles from "components/helpers/form/with-files";

function AdminUsersForm({onChange, onSubmit, value}) {
  return (
    <FormWithFiles onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="user-first-name">First Name</label>
            </div>
            <input
              className="form-control"
              id="user-first-name"
              name="first_name"
              onChange={onChange}
              placeholder="Mindy (Optional)"
              type="text"
              value={value("first_name")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="user-last-name">Last Name</label>
            </div>
            <input
              className="form-control"
              id="user-last-name"
              name="last_name"
              onChange={onChange}
              placeholder="Kaling (Optional)"
              type="text"
              value={value("last_name")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="user-email">Email</label>
            </div>
            <input
              className="form-control"
              id="user-email"
              name="email"
              onChange={onChange}
              placeholder="mindy@kaling.com"
              required={true}
              type="email"
              value={value("email")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="user-image">Image</label>
            </div>
            <FileField id="user-image" name="image" onChange={onChange} />
            {value("image") && <img alt="User" className="img-fluid mt-2" src={value("image")} />}
          </div>
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("admin", false)}
              className="custom-control-input"
              id="user-admin"
              name="admin"
              onChange={onChange}
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="user-admin">Admin</label>
          </div>
          {!value("invited") && (
            <>
              <div className="custom-control custom-switch text-center mb-3">
                <input
                  checked={value("post_notifications", false)}
                  className="custom-control-input"
                  id="user-post-notifications"
                  name="post_notifications"
                  onChange={onChange}
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="user-post-notifications">Post Notifications</label>
              </div>
              <div className="custom-control custom-switch text-center mb-3">
                <input
                  checked={value("newsletter_notifications", false)}
                  className="custom-control-input"
                  id="user-newsletter-notifications"
                  name="newsletter_notifications"
                  onChange={onChange}
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="user-newsletter-notifications">Newsletter Notifications</label>
              </div>
            </>
          )}
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </FormWithFiles>
  );
}

AdminUsersForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminUsersForm;
