import {useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {destroy as destroySession} from "app/requests/session";
import {ActionText, ShareButtons} from "components/helpers";
import {useContent, useNavbar} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";

export default function Navbar() {
  const [search, setSearch] = useState("");
  const logo = useContent("Navbar", "Logo");
  const subtext = useContent("Navbar", "Subtext");
  const shareTitle = useContent("Sharing", "Title");
  const instagram = useContent("Social", "Instagram");
  const linkedin = useContent("Social", "Linkedin");
  const youtube = useContent("Social", "Youtube");
  const {channel, dispatch, page, user} = useAppContext();
  const [showShare, setShowShare] = useState(false);
  const ref = useRef(null);
  const {toggleMenu} = useNavbar({ref});
  const signOut = () => {
    destroySession().then((data) => {
      if(data.success) {
        window.location.href = `/subscribe?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };

  const shareUrl = window.location.origin;
  const share = () => setShowShare(!showShare);

  return (
    <nav className="public-navbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div className="navbar-links d-none d-lg-block">
              <NavLink className="navbar-link" to="/episodes">Podcast</NavLink>
              <NavLink className="navbar-link" to="/transcripts">Transcripts</NavLink>
              <NavLink className="navbar-link" to="/videos">Videos</NavLink>
              <NavLink className="navbar-link" to="/about">About</NavLink>
              <NavLink className="navbar-link" to="/contact">Contact</NavLink>
              {!user && <NavLink className="navbar-link" to="/subscribe">Subscribe</NavLink>}
              {user && (
                <>
                  <NavLink className="navbar-link" to="/profile">Settings</NavLink>
                  <button className="navbar-link" onClick={signOut} type="button">
                    <i className="fas fa-fw fa-sign-out" />
                  </button>
                </>
              )}
            </div>
            <div className="navbar-links navbar-links-responsive d-block d-lg-none" ref={ref}>
              <button className="my-3" onClick={toggleMenu} type="button">
                <i className="fas fa-grip-lines fa-2x font-weight-normal" />
              </button>
              <div className="navbar-collapse pl-1 pr-3 pt-2">
                <button className="mb-2 navbar-link d-block float-right" onClick={toggleMenu} type="button">
                  <i className="fas fa-fw fa-times fa-2x font-weight-normal" />
                </button>
                <NavLink className="navbar-link d-block" to="/episodes">Podcast</NavLink>
                <NavLink className="navbar-link d-block" to="/transcripts">Transcripts</NavLink>
                <NavLink className="navbar-link d-block" to="/about">About</NavLink>
                <NavLink className="navbar-link d-block" to="/contact">Contact</NavLink>
                {user && (
                  <>
                    <NavLink className="navbar-link" to="/profile">Settings</NavLink>
                    <button className="navbar-link" onClick={signOut} type="button">
                      <i className="fas fa-fw fa-sign-out" />
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr className="navbar-hr d-none d-lg-block" />
            <div className="my-3">
              {logo && (
                <a href="/">
                  <img alt="The Visible Voices Logo" height="150" src={logo} />
                </a>
              )}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="navbar-links">
              <form action="/search" className="form-inline">
                <input className="form-control mr-sm-2" name="value" onChange={({target: {value}}) => setSearch(value)} placeholder="SEARCH" type="search" value={search} />
                {search && <button className="btn btn-primary btn-sm" type="submit">Search</button>}
              </form>
            </div>
            <hr className="navbar-hr" />
            <div className="navbar-side-content my-3">
              <div className="navbar-icons">
                {channel && channel.itunes_link && (
                  <a className="navbar-link" href={channel.itunes_link} rel="noopener noreferrer" target="_blank" title="Apple Podcasts">
                    <i className="fas fa-fw fa-lg fa-podcast" />
                  </a>
                )}
                {youtube && (
                  <a className="navbar-link" href={`https://www.youtube.com/@${youtube}`} rel="noopener noreferrer" target="_blank" title="Youtube">
                    <i className="fab fa-fw fa-lg fa-youtube" />
                  </a>
                )}
                {instagram && (
                  <a className="navbar-link" href={`https://www.instagram.com/${instagram}`} rel="noopener noreferrer" target="_blank" title="Instagram">
                    <i className="fab fa-fw fa-lg fa-instagram" />
                  </a>
                )}
                {linkedin && (
                  <a className="navbar-link" href={`https://www.linkedin.com/company/${linkedin}`} rel="noopener noreferrer" target="_blank" title="LinkedIn">
                    <i className="fab fa-fw fa-lg fa-linkedin" />
                  </a>
                )}
                {channel && channel.spotify_link && (
                  <a className="navbar-link" href={channel.spotify_link} rel="noopener noreferrer" target="_blank" title="Spotify Podcasts">
                    <i className="fab fa-fw fa-lg fa-spotify" />
                  </a>
                )}
                <button className="navbar-link" onClick={share} title="Share" type="button">
                  <i className="fas fa-fw fa-lg fa-share-alt" />
                </button>
                <a className="navbar-link" href="/feed" target="_blank" title="RSS Feed">
                  <i className="fas fa-fw fa-lg fa-rss" />
                </a>
              </div>
              {showShare && <ShareButtons title={shareTitle} url={shareUrl} />}
              <div className="text-accent text-uppercase">
                {subtext && <ActionText.Content html={subtext} />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr className="navbar-hr" />
            {page.heading && (
              <div className="row">
                <div className="col-lg-9">
                  <h1 className="page-heading">{page.heading}</h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
