import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";
import FileField from "components/helpers/form/file-field";
import FormWithFiles from "components/helpers/form/with-files";

function AdminContributersForm({onChange, onSubmit, value}) {
  return (
    <FormWithFiles onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="mb-3">
            <ContentEditor
              id="contributer-bio"
              label="Bio"
              name="bio"
              onChange={onChange}
              value={value("bio")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contributer-name">Name</label>
            </div>
            <input
              className="form-control"
              id="contributer-name"
              name="name"
              onChange={onChange}
              placeholder="Resa E Lewiss MD"
              required={true}
              type="text"
              value={value("name")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contributer-rank">Rank</label>
            </div>
            <input
              className="form-control"
              id="contributer-rank"
              name="rank"
              onChange={onChange}
              placeholder="10"
              required={true}
              type="number"
              value={value("rank")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contributer-title">Title</label>
            </div>
            <input
              className="form-control"
              id="contributer-title"
              name="title"
              onChange={onChange}
              placeholder="Witty and Insightful"
              required={true}
              type="text"
              value={value("title") || ""}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contributer-website">Website</label>
            </div>
            <input
              className="form-control"
              id="contributer-website"
              name="website"
              onChange={onChange}
              placeholder="https://www.tomify.me"
              type="url"
              value={value("website") || ""}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contributer-image">Image</label>
            </div>
            <FileField id="contributer-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Contributer" className="img-fluid" src={value("image")} />}
          </div>
          <div className="custom-control custom-switch text-center mb-3">
            <input
              checked={value("active") || false}
              className="custom-control-input"
              id="contributer-active"
              name="active"
              onChange={onChange}
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="contributer-active">Active</label>
          </div>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </FormWithFiles>
  );
}

AdminContributersForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminContributersForm;
