import PropTypes from "prop-types";
import {useLayoutEffect, useRef, useState} from "react";
import {
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import {useContent} from "lib/hooks";

function ShareButtons({title, url}) {
  const shareButtonProps = {url};
  const input = useRef();
  const shareIconProps = {bgStyle: {fill: "#062F89"}, borderRadius: 10, size: 24};
  const [showLink, setShowLink] = useState(false);
  const twitterUsername = useContent("Social", "Twitter");
  const linkedinShare = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "", "height=600,width=750");
  };

  useLayoutEffect(() => {
    if(!showLink) { return; }

    input.current.select();
  });

  return (
    <div className="share-buttons">
      <button onClick={linkedinShare} type="button">
        <LinkedinIcon {...shareIconProps} />
      </button>
      <TwitterShareButton {...shareButtonProps} title={title} via={twitterUsername}>
        <TwitterIcon {...shareIconProps} />
      </TwitterShareButton>
      <a className="share-link" href={`mailto:?body=${url}&subject=${title}`} rel="noreferrer" target="_blank">
        <i className="fas fa-envelope" />
      </a>
      <button className="share-link" onClick={() => setShowLink(!showLink)} type="button">
        <i className="fas fa-link" />
      </button>
      {showLink && <input className="form-control" readOnly={true} ref={input} type="url" value={url} />}
    </div>
  );
}

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default ShareButtons;
