// equivalent of import "@rails/actiontext" until issue is resolved
//   https://github.com/rails/rails/issues/43973
import {AttachmentUpload} from "@rails/actiontext/app/javascript/actiontext/attachment_upload";

document.addEventListener("trix-attachment-add", (event) => {
  const {attachment, target} = event;

  if(attachment.file) {
    const upload = new AttachmentUpload(attachment, target);
    upload.start();
  }
});
