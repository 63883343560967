import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyMessage} from "app/requests/admin/messages";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminMessagesListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(window.confirm("Are you sure you want to delete this message?")) {
      destroyMessage(record.id).then((data) => {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "danger"
        }));

        if(data.success) { onDestroy(record.id); }
      });
    }
  };

  return (
    <tr>
      <td>{record.first_name} {record.last_name}</td>
      <td>{record.email}</td>
      <td>{record.subject}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/messages/${record.id}`}>View</a>
          <button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminMessagesListRow.propTypes = {
  record: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired
  }).isRequired,
  onDestroy: PropTypes.func.isRequired
};

export default AdminMessagesListRow;
