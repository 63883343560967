import {SET_PAGE, SET_SIDEBAR} from "app/actions/page";

export default function page({action, state = null}) {
  switch(action.type) {
    case SET_PAGE: {
      const data = action.payload;

      return {...data, sidebar: state.sidebar};
    }
    case SET_SIDEBAR:
      return {...state, sidebar: action.payload};
    default:
      return state;
  }
}
