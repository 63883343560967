import {useEffect, useState} from "react";
import {get as getDashboard} from "app/requests/admin/dashboard";
import {Loading} from "components/pages";
import usePage from "lib/hooks/use-page";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState(null);

  usePage({heading: "Dashboard"});
  useEffect(() => {
    setLoading(true);
    getDashboard().then((data) => {
      setRecord({...data});
      setLoading(false);
    });
  }, []);

  if(loading) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center">Dashboard</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="table-responsive rounded">
            <table className="table table-hover text-center">
              <thead className="thead-dark">
                <tr>
                  <th>Newsletter Subscribers</th>
                  <th>Post Subscribers</th>
                  <th>Total Users</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{record.subscribers.newsletter}</td>
                  <td>{record.subscribers.post}</td>
                  <td>{record.users.total}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
