import {usePage} from "lib/hooks";

export default function Disclaimer() {
  usePage({heading: "Disclaimer"});

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <p>
            By using this website or its contents you agree not to use this website as medical
            advice to treat any medical condition in either yourself or others, including but not
            limited to patients that you are treating. Consult your own physician for any medical
            issues that you may be having. This entire disclaimer also applies to any guests or
            contributors to the podcast or blog. Under no circumstances shall this website or any
            contributors to the website be responsible for damages arising from use of the website.
            Furthermore, this website should not be used in any legal capacity whatsoever, including
            but not limited to establishing "standard of care" in a legal sense or as a basis for
            expert witness testimony. No guarantee is given regarding the accuracy of any statements
            or opinions made on this website. Comments made on this website are considered to be
            public comments, and may be used in other online or print media in the future. The
            opinions on this website are the opinions of the authors and do not represent the views
            or opinions of their employers.
          </p>
          <h2>Privacy Notice</h2>
          <p>
            While you may give your email address to subscribe to or post information on the
            website, we will never sell your email address or contact information to any third
            parties without your explicit permission.
          </p>
        </div>
      </div>
    </div>
  );
}
