import {getAll as getRecords} from "app/requests/admin/episodes";
import PaginateTable from "components/helpers/paginate-table";
import Row from "./row";

const Heading = () => (
  <tr>
    <th>Published</th>
    <th>Audio</th>
    <th>Author</th>
    <th>Channel</th>
    <th>Image</th>
    <th>Kind</th>
    <th>Title</th>
    <th>Actions</th>
  </tr>
);

export default function AdminEpisodesList() {
  const options = {Heading, Row, getRecords};

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-6 text-left mb-1">
              <a className="btn btn-outline-secondary btn-sm" href="/preview/episodes">Preview</a>
            </div>
            <div className="col-6 text-right mb-1">
              <a className="btn btn-outline-secondary btn-sm" href="/admin/episodes/new">New Episode</a>
            </div>
          </div>
          <h1 className="text-center">Episodes</h1>
          <PaginateTable {...options} />
        </div>
      </div>
    </div>
  );
}
